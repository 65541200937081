import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import font from "../../fonts/fonts.module.scss"

const Intro = ({data, sections, port}) => {
  
  return(
    <StyledIntro as={Link} to={data.link}>
      <div className="banner-name">
        {!port && <p className={font.mER} style={{letterSpacing: "0.5em", color: "#fa4616"}}>{sections[data.subsection]}</p>}
        <h2 className={font.aGB + " title"}>{data.name}</h2>
        <p className={font.mER} style={{letterSpacing: "0.13em"}}>{data.linkTxt}</p>
      </div>
      <div className="banner-img">
        <GatsbyImage image={data.img.childImageSharp.gatsbyImageData} alt={data.name}/>
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  .banner-name{
    padding: 7vw 7%;
    .title{margin: 0.2em 0;}
  }
`