import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import { Link } from "gatsby"
import font from "../../fonts/fonts.module.scss"

const Intro = ({data, reverse, port}) => {
  
  return(
    <StyledIntro style={{flexDirection: reverse && " unset"}} as={Link} to={data.link}>
      <StyledBackground fluid={data.img.childImageSharp.fluid}>
        {!port ? 
          <div className="banner-info">
            <div className="info-type">
              <p className={font.aGR}>{data.typeInfo[0]}</p>
              <p className={font.aGB}>{data.typeInfo[1]}</p>
            </div>
            <div className="info-local">
              <p className={font.aGR}>{data.localInfo[0]}</p>
              <p className={font.aGB}>{data.localInfo[1]}</p>
            </div>
          </div>
          :
          <div className="banner-info">
            <div className="info-date">
              <p className={font.aGR}>{data.local[0]}</p>
              <p className={font.aGB}>{data.local[1]}</p>
            </div>
          </div>
        }
      </StyledBackground>
      <div className="banner-name">
        <h1 className={font.aGB}>{data.name}</h1>
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled.div`
  position: relative;
  height: 33vw;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  .banner-name{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 7%;
    text-align: center;
  }
`

const StyledBackground = styled(BackgroundImage)`
  height: 100%;
  width: 60%;
  .banner-info{
    width: 100%;
    height: 100%;
    background-color: rgba(250, 70, 22, 0.7);
    opacity: 0;
    transition: opacity 300ms ease-out;
    text-transform: uppercase;
    :hover{
      opacity: 1;
    }
    color: #ffffff;
    display: flex;
    .info-type, .info-local{
      width: 50%;
      padding: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .info-date{
      width: 100%;
      padding: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
  }
`