import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import font from "../../fonts/fonts.module.scss"

const Intro = ({data, port, portMob}) => {
  
  return(
    <StyledIntro>
      <div className="active-intro-banner">
        <StyledBackground fluid={data.img.childImageSharp.fluid}>
          <div className="intro-banner-title">
            <h2 className={font.mER + " title"} style={{marginTop: port ? "-3em" : portMob && "-2em"}}>{data.title}</h2>
            <p className={font.aGR + " text"}>{data.text}</p>
          </div>
        </StyledBackground>
        <div className="intro-banner-moto">
          <h1 className={font.aGB}>{data.moto}</h1>
        </div>
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled.div`
  position: relative;
  width: 100%;
  .active-intro-banner{
    position: relative;
    height: 33vw;
    width: 100%;
    display: flex;
    .intro-banner-moto{
      height: 100%;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1{width: 75%; color: #fa4616;}
    }
  }
  @media only screen and (max-width: 768px){.active-intro-banner{height: 60vh;.intro-banner-moto{display: none;}}}
`

const StyledBackground = styled(BackgroundImage)`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .intro-banner-title{
    width: 60%;
    margin-left: 20%;
    .title{margin-bottom: 0.6em;}
  }
  @media only screen and (max-width: 1100px){
    .intro-banner-title{
      width: 80%;
      margin-left: 10%;
    }
  }
  @media only screen and (max-width: 950px){
    .intro-banner-title{
      width: 90%;
      margin-left: 5%;
    }
  }
  @media only screen and (max-width: 830px){
    .intro-banner-title{
      width: 96%;
      margin-left: 2%;
    }
  }
  @media only screen and (max-width: 768px){
    width: 100%;
    .intro-banner-title{
      margin-top: -7vw;
      width: 86%;
      margin-left: 7%;
      .title{margin-bottom: 0.2em;}
    }
  }
`