import React, { useState } from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Selector = ({data, current, setFilter, sections}) => {
  const [open, setOpen] = useState(false);
  
  return(
    <StyledSelector onClick={()=>{setOpen(!open)}}>
      <p className={font.aGR}>
        {data}
        {current>=0 && <span> - <span style={{color: "#fa4616"}}>{sections[current]}</span></span>}
      </p>
      <div className="drawer" style={{height: open ? "75vw" : "0", padding: open ? "8px 0" : "0"}}>
        {sections.map((data, i)=>(
          <button onClick={()=>{setFilter(i); setOpen(!open)}} key={"keyBtn" + i}>
            <p className={font.aGB} style={{color: current===i && "#fa4616"}}>{data}</p>
            <hr/>
          </button>
        ))}
        <button onClick={()=>{setFilter(-1); setOpen(!open)}}><p className={font.aGB} style={{color: current===-1 && "#fa4616"}}>TODOS</p></button>
      </div>
    </StyledSelector>
  )
}

export default Selector

const StyledSelector = styled.div`
  position: relative;
  background-color: #000000;
  color: #ffffff;
  padding: 2vh 15%;
  display: flex;
  justify-content: space-around;
  p{
    text-transform: uppercase;
  }
  .drawer{
    width: 100%;
    background-color: #ffffff;
    color: #000000;
    position: absolute;
    top: 100%;
    overflow: hidden;
    z-index: 6;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: all 300ms ease-out;
    hr{
      width: 86%;
      border: 1px solid #fa4616;
    }
    button{padding: 0 30%;width: 100%;text-align: center;p{padding: 1em 0;}}
  }
`