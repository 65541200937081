import React from "react"
import styled from "styled-components"
import Div100vh from 'react-div-100vh'
import font from "../../fonts/fonts.module.scss"

const Index = ({data, current, reset, sections}) => {
  
  return(
    <StyledIndex>
      <button className="tab1" onClick={()=>{reset(-1)}}>
        <p className={font.aGB + " small"}>
          {data.section}
          {current !== -1 && 
            <> - {sections[current]}</>
          }
        </p>
      </button>
    </StyledIndex>
  )
}

export default Index

const StyledIndex = styled(Div100vh)`
  position: fixed;
  top: 0;
  left: 0;
  width: fit-content;
  display: flex;
  z-index: 4;
  .tab1{
    position: relative;
    background-color: #fa4616;
    width: clamp(36px, 2vw, 72px);
    color: #ffffff;
    p{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      white-space: nowrap;
    }
  }
`