import React, { useState, useEffect } from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import Banner from "./ativos-intro-banner"

const List = ({data, more, current, port}) => {
  const [slice, setSlice] = useState(3);
  const [dataFiltered, setDataFiltered] = useState(data);

  useEffect(() => {
    setDataFiltered(
      data.filter(function(el){
        if(current<0){
          return el.subsection>-1;
        }
        else{
          return el.subsection===current;
        }
      })
    )
  }, [current, data]);

  return(
    <StyledList>
      {dataFiltered.slice(0, slice).map((data, i)=>(
        <span key={"project-banner-" + i}>
          <Banner data={data} reverse={i%2!==0 && true} port={port}/>
        </span>
      ))}
      {dataFiltered.length===0 && 
        <h3 className={font.aGB + " emptyFilter"}>{more[1]}</h3>
      }
      {slice<dataFiltered.length && 
        <div className="more">
          <button onClick={()=>{setSlice(slice+3)}}><p className={font.aGB + " small"}>{more[0]}</p></button>
        </div>
      }
    </StyledList>
  )
}

export default List

const StyledList = styled.div`
  position: relative;
  .emptyFilter{
    text-align: center;
    color: #fa4616;
    padding: 7vw;
  }
  .more{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 7vw;
    button{padding: 2vw;}
  }
`