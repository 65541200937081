import React from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Selector = ({data, current, setFilter, sections}) => {

  const select = (i) => {
    if(current===i){
      setFilter(-1);
    }
    else{
      setFilter(i);
    }
  }
  
  return(
    <StyledSelector>
      <p className={font.aGR + " small"}>{data}</p>
      {sections.map((data, i)=>(
        <button onClick={()=>select(i)} key={"keyBtn" + i}><p className={font.aGB + " small"} style={{color: current===i && "#fa4616"}}>{data}</p></button>
      ))}
    </StyledSelector>
  )
}

export default Selector

const StyledSelector = styled.div`
  position: relative;
  background-color: #000000;
  color: #ffffff;
  padding: 2vh 15%;
  display: flex;
  justify-content: space-around;
  button{
    transition: color 200ms ease-out;
    :hover{
      color: #fa4616;
    }
  }
`